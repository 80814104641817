import { getBrowsePath, refinementKeySort, refinementSort } from '@sainsburys-tech/boltui-utils'
import { CategoryHelper } from './CategoryHelper'
import findabilityDecoder from './FindabilityDecoder'
import config, { categoryTypes } from '../config'
import { handleRedirect, loadingEnd } from '../redux/application/application'
import { failedBrowse } from '../redux/category/category'
import { getBrand } from '../utils/ConfigProvider'
import getDefaultSort from '../utils/defaultSort'

const brand = getBrand()
const MASTER_CATEGORY_ID = '10000001'

class BrowseHelper {
  static didRosettaModifyRequest(queryParams) {
    return Object.keys(queryParams).includes('rosetta')
  }

  static structureCategoryTree(categoryTree) {
    return categoryTree ? categoryTree.split('/') : []
  }

  static stripUneededRefinements(url) {
    const matches = url.match(/(^.*c:\d*\/)/)
    return matches ? matches[0] : url
  }

  static buildQueryString(queryParams, isClearance = false) {
    let queryString = ''
    const queryFragments = []
    Object.keys(queryParams).forEach((key, i) => {
      if (key === 'clearance' && isClearance) return null

      if (i === 0) queryString += '?'
      queryFragments.push([key, queryParams[key]].join('='))
    })
    queryString += queryFragments.join('&')
    return queryString
  }

  static isNoIndex({ maxFiltersPerFacet, maxFilteredFacets }, appliedFilters) {
    if (appliedFilters.length < 1) return false
    const infiniteRefinements = '#'
    let isNoIndex = false
    if (maxFilteredFacets !== infiniteRefinements) {
      isNoIndex = appliedFilters.length > parseInt(maxFilteredFacets)
    }
    if (!isNoIndex && maxFiltersPerFacet !== infiniteRefinements) {
      isNoIndex = appliedFilters.some(filter => {
        const firstFilterKey = Object.keys(filter)[0]
        return filter[firstFilterKey].length > parseInt(maxFiltersPerFacet)
      })
    }

    return isNoIndex
  }

  static getFilterLabel(allFilters, facet, filtersSelected) {
    const filters = allFilters.find(filter => filter.id === facet.toLowerCase())
    return filtersSelected.map(filterSelected => {
      const filter = filters?.values?.find(filter => filter.id === filterSelected)
      return filter && filter.label ? filter.label : ''
    })
  }

  static getOrderedFilters(appliedFilters, allFilters) {
    if (!appliedFilters.length) return []
    const reducedFilters = appliedFilters.reduce((prev, filter) => {
      return { ...prev, ...filter }
    }, {})

    return Object.keys(reducedFilters)
      .sort(refinementKeySort)
      .reduce((prev, facet) => {
        const sortedFilterValues =
          facet.toLowerCase() === 'fasttrack'
            ? [CategoryHelper.getFastTrackLabel(allFilters)]
            : this.getFilterLabel(allFilters, facet, reducedFilters[facet]).sort(refinementSort)
        return [...prev, ...sortedFilterValues]
      }, [])
  }

  static getBrowseParamsFromBreadcrumbResponse(response) {
    let isCategoryLive = false
    const ancestors = []
    let currentCatName = ''
    let currentCatId = ''
    for (let i = 1; i < response.length; i++) {
      if (i !== response.length - 1) {
        ancestors.push(response[i])
      } else {
        currentCatId = response[i].id
        currentCatName = response[i].name
        isCategoryLive = !response[i].isWCS
      }
    }

    return { currentCatId, currentCatName, isCategoryLive, ancestors }
  }

  static getCorrectBrowseUrl(serviceResponse, isClearance = false, isSaleRoute = false) {
    if (serviceResponse && serviceResponse.data && serviceResponse.data.length) {
      const flattenedCategories = CategoryHelper.flattenAncestors(serviceResponse)
      const browseParams = {
        currentCatName: CategoryHelper.getCategoryName(serviceResponse),
        ancestors: flattenedCategories,
        currentCatId: CategoryHelper.getCategoryIdFromResponse(serviceResponse),
        isClearance,
        isSale: isSaleRoute
      }
      const validCategories = this.buildCorrectUrlBrowseParams(browseParams)
      return getBrowsePath(validCategories)
    }

    return ''
  }

  static shouldRedirect(currentUrl, correctUrl, refinements) {
    // TODO: fix the routes regex to pick up the trailing slash in refinements
    // if (!!refinements && !refinements.endsWith('/')) {
    //   return true
    // }
    const isRefinementCapitalised = !!findabilityDecoder.getSanitised(refinements).match('[A-Z]')
    return currentUrl !== correctUrl || isRefinementCapitalised
  }

  static constructBrowseUrlRedirect(url, refinements, queryParams) {
    if (!!refinements && !refinements.endsWith('/')) {
      refinements += '/'
    }

    return url + findabilityDecoder.lowerCaseEncodedRefinements(refinements) + queryParams
  }

  static hasProducts(serviceResponse) {
    if (serviceResponse && !!serviceResponse.data && serviceResponse.data[0].attributes) {
      // Uncomment this to be able to access the master category
      // if (serviceResponse.data[0].id === MASTER_CATEGORY_ID) return true
      return (
        serviceResponse.data[0].attributes.productsInCategory !== 0 ||
        serviceResponse.data[0].attributes.productsInCategoryTree !== 0
      )
    }

    return false
  }

  static constructRedirectData(correctBrowseUrl, refinements, queryString, isRelative) {
    return {
      path: this.constructBrowseUrlRedirect(correctBrowseUrl, refinements, queryString),
      isRelative
    }
  }

  static redirectTo(redirectData, context, statusCode = config.httpStatusCodes.permanentRedirect) {
    redirectData.statusCode = statusCode
    context.reduxStore.dispatch(handleRedirect(redirectData))
  }

  static buildCorrectUrlBrowseParams(browseParams) {
    let categories = []
    const params = {}
    const {
      currentCatName,
      ancestors,
      currentCatId,
      futureCatName,
      filterUriFragments,
      sortBy,
      pageNumber,
      isClearance,
      isSale
    } = browseParams

    categories = ancestors
      .filter(category => {
        if (category.id !== MASTER_CATEGORY_ID) {
          return category
        }
      })
      .map(category => {
        if (category.attributes) return category.attributes.name
        if (category.name) return category.name
      })

    if (currentCatName) {
      categories.push(currentCatName)
    }
    if (futureCatName) {
      categories.push(futureCatName)
    }
    if (filterUriFragments) {
      const { clearance, ...otherFilters } = filterUriFragments // remove clearance:true from URL path
      params.filterUriFragments = otherFilters
    }
    if (sortBy) {
      params.sortBy = sortBy
    }
    if (pageNumber) {
      params.pageNumber = pageNumber
    }
    if (isClearance) {
      params.isClearance = true
    }
    if (isSale) {
      params.isSale = true
    }
    if ((brand === 'tu' || isClearance) && getDefaultSort(false, true, currentCatId, isClearance) === 'newness') {
      params.defaultSortKey = 'newness_desc'
    }

    params.categories = categories
    params.categoryId = currentCatId

    return params
  }

  static getAncestorsForBrowseBreadcrumb(categoryAncestors = [], isClearance = false, isSaleRoute) {
    categoryAncestors = categoryAncestors.filter(ancestor => ancestor.id !== MASTER_CATEGORY_ID)

    return categoryAncestors.map((ancestor, index) => {
      const browseParams = {
        ancestors: categoryAncestors.slice(0, index),
        currentCatId: ancestor.id,
        currentCatName: ancestor.attributes.name,
        isClearance,
        isSale: isSaleRoute
      }
      return {
        name: ancestor.attributes.name,
        path: getBrowsePath(BrowseHelper.buildCorrectUrlBrowseParams(browseParams))
      }
    })
  }

  static buildUrlForCategory(categoryParameters) {
    const {
      futureCategoryId,
      futureCategoryName,
      categoryType,
      ancestors,
      currentCategoryId,
      currentCategoryName,
      isPlp: currentCategoryIsPlp,
      isClearance,
      isSaleRoute
    } = categoryParameters
    const ancestorsForUrl = []
    ancestorsForUrl.push(...ancestors.filter(ancestor => ancestor.id !== futureCategoryId))
    if ((categoryType === categoryTypes.PLP && !currentCategoryIsPlp) || isClearance) {
      ancestorsForUrl.push({
        id: currentCategoryId,
        attributes: {
          name: currentCategoryName
        }
      })
    }
    const params = {
      currentCatName: futureCategoryName,
      ancestors: ancestorsForUrl,
      currentCatId: futureCategoryId,
      isClearance,
      isSale: isSaleRoute
    }
    return getBrowsePath(BrowseHelper.buildCorrectUrlBrowseParams(params))
  }

  static handleBadBrowseResponse(response, meta, context, done, err, executeLoadingEnd = true) {
    const { httpStatusCodes } = config
    let status
    if (response && response.status) {
      if (response.response) {
        status = this.hasProducts(response.response) ? response.status : httpStatusCodes.notFound
      } else {
        status = response.status
      }
    } else {
      status =
        !!meta && !!meta.statusCode && (meta.statusCode === httpStatusCodes.serverError || err)
          ? httpStatusCodes.serverError
          : httpStatusCodes.notFound
    }
    context.reduxStore.dispatch(failedBrowse({ status }))
    if (executeLoadingEnd) {
      context.reduxStore.dispatch(loadingEnd())
    }
    return done()
  }

  static determineRedirectStatusCode(isCategoryLive) {
    const { httpStatusCodes } = config
    return isCategoryLive ? httpStatusCodes.permanentRedirect : httpStatusCodes.temporaryRedirect
  }

  static getDfpTaxonomyQuery(categoryAncestors, categoryName) {
    if (!(Array.isArray(categoryAncestors) && categoryAncestors.length)) return ''
    const categories = categoryAncestors
      .filter(ancestor => ancestor.id !== MASTER_CATEGORY_ID)
      .map(category => category.attributes.name)
    categories.push(categoryName)
    return categories.map(category => findabilityDecoder.stripPunctuation(category.toLowerCase())).join('/')
  }

  static getDfpListerQuery(pageType = 'search', searchTerm = '', categoryName = '') {
    const transformPath = pageType =>
      ({
        directory: 'sd',
        canned: 'list',
        browse: 'plp',
        search: 'search',
        clearance: 'clearance'
      }[pageType])

    const path = transformPath(pageType)
    const term = path === ('plp' || 'clearance') ? categoryName : searchTerm

    return term !== null ? `${path}/${term.replace(/\s/g, '-').toLowerCase()}` : ''
  }
}

export { BrowseHelper, MASTER_CATEGORY_ID }
