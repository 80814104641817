import { handleCategory, handleBrowse, handlePLP, failedBrowse } from '../redux/category/category'
import { failedSearch, handleSearch } from '../redux/product/product'
import { lostConnection, connectionTimeout, handleContent } from '../redux/application/application'

import Logger from '../utils/Logger'

const logger = new Logger()

export const FAILED_SEARCH_ACTION = 'SEARCH_FAILURE'
export const FAILED_BROWSE_ACION = 'BROWSE_FAILURE'
export const FAILED_CONTENT_ACTION = 'CONTENT_FAILURE'
export const PLP_SUCCESS_ACTION = 'PLP'

export class ServiceHelper {
  static buildProductServiceRequest(params, context) {
    return {
      service: 'product',
      reduxAction: payload => context.reduxStore.dispatch(handleSearch(payload)),
      params
    }
  }

  static buildCategoryServiceRequest(params, context) {
    return {
      service: 'category',
      reduxAction: payload => context.reduxStore.dispatch(handleBrowse(payload)),
      params
    }
  }

  static buildNoResultsCategoryServiceRequest(params) {
    return {
      service: 'category',
      params
    }
  }

  static buildBreadcrumbServiceRequest(params, context) {
    return {
      service: 'breadcrumb',
      reduxAction: payload => context.reduxStore.dispatch(handleCategory(payload)),
      params
    }
  }

  static buildRedirectTaxonomyServiceRequest(params) {
    return {
      service: 'redirectTaxonomy',
      params
    }
  }

  static buildContentServiceRequest(params, context, urlRefinements = '') {
    return {
      service: 'content',
      params,
      reduxAction: payload => context.reduxStore.dispatch(handleContent({ ...payload, urlRefinements }))
    }
  }

  static buildSearchContentServiceRequest(params) {
    return {
      service: 'content',
      params
    }
  }

  static handleConnectionError(err, context, ...args) {
    logger.debug('functionLogger', { args }, 'ServiceHelper handleConnectionError')

    const actions = {}
    // This scenario is an AJAX error. Either no internet connection, or connection times out
    actions.loadingEnd = true
    if (err.code === 'ETIMEDOUT') {
      actions.reduxAction = payload => context.reduxStore.dispatch(connectionTimeout(payload))
    } else {
      actions.reduxAction = payload => context.reduxStore.dispatch(lostConnection(payload))
    }
    actions.payload = null
    return actions
  }

  static isConnectionError(err, ...args) {
    logger.debug('functionLogger', { args }, 'ServiceHelper is_connectionError')

    return err && err.message && !err.body
  }

  static isGenericError(err, serviceResponse, ...args) {
    logger.debug('functionLogger', { args }, 'ServiceHelper is_genericError')

    const responseAttribute = serviceResponse && serviceResponse.response ? serviceResponse.response : null
    return err || !responseAttribute
  }

  static handleSuccess(request, response, loadingEnd, context, ...args) {
    logger.debug('functionLogger', { args }, 'ServiceHelper handleSuccess')

    const actions = {}
    actions.loadingEnd = loadingEnd
    actions.reduxAction = payload => context.reduxStore.dispatch(handleSearch(payload))
    actions.payload = response
    return actions
  }

  static handlePLPRequest(err, categoryResponse, productResponse, context, ...args) {
    logger.debug('functionLogger', { args }, 'ServiceHelper handlePLPRequest')

    let actions = {}
    if (this.isConnectionError(err)) {
      actions = this.handleConnectionError(err, context)
    } else if (this.isGenericError(err, productResponse)) {
      actions.loadingEnd = true
      actions.reduxAction = payload => context.reduxStore.dispatch(failedSearch(payload))
      actions.payload = err && err.body ? err.body.data : productResponse
    } else {
      actions.loadingEnd = true
      actions.reduxAction = payload => context.reduxStore.dispatch(handlePLP(payload))
      actions.payload = { categoryResponse, productResponse }
    }
    return actions
  }

  static handleBrowseRequest(err, categoryResponse, context, ...args) {
    logger.debug('functionLogger', { args }, 'ServiceHelper handleBrowseRequest')

    let actions = {}
    if (this.isConnectionError(err)) {
      actions = this.handleConnectionError(err, context)
    } else if (this.isGenericError(err, categoryResponse)) {
      actions.loadingEnd = true
      actions.reduxAction = payload => context.reduxStore.dispatch(failedBrowse(payload))
      actions.payload = err && err.body ? err.body.data : categoryResponse
    }
    return actions
  }

  static handleProductRequest(err, productResponse, productRequest, context, ...args) {
    logger.debug('functionLogger', { args }, 'ServiceHelper handleProductRequest')

    let actions = {}
    if (this.isConnectionError(err)) {
      actions = this.handleConnectionError(err, context)
    } else if (this.isGenericError(err, productResponse)) {
      actions.loadingEnd = true
      actions.reduxAction = payload => context.reduxStore.dispatch(failedSearch(payload))
      actions.payload = err && err.body ? err.body.data : productResponse
    } else {
      actions = this.handleSuccess(productRequest, productResponse, false, context)
    }
    return actions
  }

  static handleCategoryRequest(err, categoryResponse, categoryRequest, context, ...args) {
    logger.debug('functionLogger', { args }, 'ServiceHelper handleCategoryRequest')
    let actions = {}
    if (this.isConnectionError(err)) {
      actions = this.handleConnectionError(err, context)
    } else if (this.isGenericError(err, categoryResponse)) {
      actions.loadingEnd = true
      actions.reduxAction = payload => context.reduxStore.dispatch(handleCategory(payload))
      actions.payload = null
    } else {
      actions = this.handleSuccess(categoryRequest, categoryResponse, true, context)
    }
    return actions
  }

  static handleTreeRequest(err, treeResponse, treeRequest, context, ...args) {
    logger.debug('functionLogger', { args }, 'ServiceHelper handleTreeRequest')
    let actions = {}
    if (this.isConnectionError(err)) {
      actions = this.handleConnectionError(err, context)
    } else if (this.isGenericError(err, treeResponse)) {
      actions.loadingEnd = true
      actions.reduxAction = payload => context.reduxStore.dispatch(handleCategory(payload))
      actions.payload = null
    } else {
      actions = this.handleSuccess(treeRequest, treeResponse, true, context)
    }
    return actions
  }

  static handleActions(context, actions) {
    if (actions.dispatch) {
      context.dispatch(actions.dispatch, actions.payload)
    }
    if (actions.reduxAction !== undefined) {
      // Ugly hook-in, if needed passes payload, but the action might not use it
      actions.reduxAction(actions.payload)
    }
  }
}
