import { get } from '../utils/PolyfillLodash'
import config from '../config'
import { PLATFORM_TYPE, getPlatform } from './DisplayHelper'
import { CategoryHelper } from './CategoryHelper'
import executeCitrus from '../actions/executeCitrus'

const MAX_NUM_OF_CITRUS_PRODUCTS_ON_MOBILE = 2
const MAX_NUM_OF_CITRUS_PRODUCTS_ON_DESKTOP = 3

const insertAtPosition = (products, index, product) => [...products.slice(0, index), product, ...products.slice(index)]

const insertProducts = (products, citrusProducts, insertionIndexes) => {
  if (!Array.isArray(products) || products.length === 0) {
    return []
  }

  if (
    !Array.isArray(insertionIndexes) ||
    insertionIndexes.length === 0 ||
    !Array.isArray(citrusProducts) ||
    citrusProducts.length === 0
  ) {
    return products
  }

  // FIXME: Why we are adding the relevancy rank here - Not relevant with the main goal of this function
  citrusProducts.map(product => (product.attributes.relevancyRank = 1))

  // Limiting the correct number of sponsored products to insert when there are less that the provided insertion indices
  const insertionIndexList =
    insertionIndexes.length > citrusProducts.length
      ? insertionIndexes.slice(0, citrusProducts.length)
      : insertionIndexes

  // Limiting the maximum number of sponsored products to insert provided by the insertion index array
  return insertionIndexList.reduce((products, position, index) => {
    return insertAtPosition(products, position, citrusProducts[index])
  }, products)
}

const getMaxNumberOfAds = () => {
  const platformType = getPlatform()
  return getMaxNumberOfCitrusProductsToDisplayForPlatform(platformType)
}

const hasMinNumberOfProductsToMergeForPlatform = (products, platformType) => {
  const MIN_PRODUCTS_FOR_MOBILE_TABLET = 2
  const MIN_PRODUCTS_FOR_DESKTOP = 3

  if (!Array.isArray(products) || !platformType) {
    return false
  }

  const numOfProducts = products.length
  const minNumOfProductsForMerging =
    platformType === PLATFORM_TYPE.MOBILE ? MIN_PRODUCTS_FOR_MOBILE_TABLET : MIN_PRODUCTS_FOR_DESKTOP

  return numOfProducts >= minNumOfProductsForMerging
}

const getMaxNumberOfCitrusProductsToDisplayForPlatform = platformType => {
  switch (platformType) {
    case PLATFORM_TYPE.MOBILE:
      return MAX_NUM_OF_CITRUS_PRODUCTS_ON_MOBILE
    case PLATFORM_TYPE.DESKTOP:
      return MAX_NUM_OF_CITRUS_PRODUCTS_ON_DESKTOP
    default:
      return 0
  }
}

const getSponsoredProductsInsertionPositions = platformType => {
  const INSERTION_INDEXES_FOR_MOBILE_TABLET = [2, 3]
  const INSERTION_INDEXES_FOR_DESKTOP = [3, 4, 5]

  switch (platformType) {
    case PLATFORM_TYPE.MOBILE:
      return INSERTION_INDEXES_FOR_MOBILE_TABLET
    case PLATFORM_TYPE.DESKTOP:
      return INSERTION_INDEXES_FOR_DESKTOP
    default:
      return []
  }
}

// Merges sponsored products within a product list by inserting them at specific locations
// FIXME: method does not resolve duplication of products
const mergeProducts = (products, citrusProducts) => {
  if (!Array.isArray(products)) {
    return []
  }

  if (!Array.isArray(citrusProducts) || citrusProducts?.length === 0) {
    return products
  }

  // Need to check for the platform as we are not providing regular and citrus products separately to the React components
  // To do so means that the entire Citrus flow would need to be re-engineered
  const platform = getPlatform()
  if (!platform) {
    return products
  }

  // If more than the required number of non-sponsored product results, allow ads/sponsored products to be inserted
  const hasMinNumberOfProductsToMerge = hasMinNumberOfProductsToMergeForPlatform(products, platform)
  if (hasMinNumberOfProductsToMerge && citrusProducts.length > 0) {
    const citrusInsertionPositions = getSponsoredProductsInsertionPositions(platform)
    return insertProducts(products, citrusProducts, citrusInsertionPositions)
  }

  return products
}

const citrusCategoryProductHandler = (pageType, context, categoryResponse, productResponse) => {
  const { initialRenderComplete } = context.reduxStore.getState().application
  if (initialRenderComplete && productResponse && productResponse.status === config.httpStatusCodes.success) {
    const categoryType = get(categoryResponse, 'response.data[0].type')
    const categoryTree = CategoryHelper.flattenAncestors(categoryResponse.response)
    executeCitrus(context, {
      keyword: '',
      appliedFilters: get(productResponse, 'response.meta.appliedFilters'),
      appliedSorts: get(productResponse, 'response.meta.appliedSorts'),
      currentPage: get(productResponse, 'response.meta.currentPage'),
      categoryTree,
      currentCategory: {
        id: get(categoryResponse, 'response.data[0].id'),
        name: get(categoryResponse, 'response.data[0].attributes.name')
      },
      isPlp: categoryType === 'PLP' || categoryType === 'PLPPLUS',
      pageType
    })
  }
}

const getCitrusProducts = (pageType, context, searchTerm, productResponse, categoryResponse) => {
  const { initialRenderComplete } = context.reduxStore.getState().application
  let categoryTree = null
  if (categoryResponse !== undefined) {
    if (categoryResponse.response && categoryResponse.response.data) {
      categoryTree = get(categoryResponse, 'response.data')
    } else {
      categoryTree = get(categoryResponse, 'response.included')
    }
  }
  if (initialRenderComplete && productResponse && productResponse.status === config.httpStatusCodes.success) {
    executeCitrus(context, {
      keyword: searchTerm,
      appliedFilters: get(productResponse, 'response.meta.appliedFilters'),
      appliedSorts: get(productResponse, 'response.meta.appliedSorts'),
      currentPage: get(productResponse, 'response.meta.currentPage'),
      categoryTree,
      pageType
    })
  }
}

const isProdListDesktopValid = prodList => {
  const prodListLength = get(prodList, 'length')
  return prodListLength > 0 && prodListLength <= MAX_NUM_OF_CITRUS_PRODUCTS_ON_DESKTOP
}

const isProdListMobileTabletValid = prodList => {
  const prodListLength = get(prodList, 'length')
  return prodListLength > 0 && prodListLength <= MAX_NUM_OF_CITRUS_PRODUCTS_ON_MOBILE
}

const isProdListValid = productList => {
  const platformType = getPlatform()
  return (
    (platformType === PLATFORM_TYPE.DESKTOP && isProdListDesktopValid(productList)) ||
    (platformType === PLATFORM_TYPE.MOBILE && isProdListMobileTabletValid(productList))
  )
}

const getUserIdFromCookieInfo = cookieInfo => {
  const AMCV_MATCH = /AMCV_[0-9A-Z%]+AdobeOrg=([0-9a-zA-Z%\-_]+)/g.exec(cookieInfo)
  const S_VI_MATCH = /s_vi=([0-9a-zA-Z%\-|[\]]+)/g.exec(cookieInfo)
  const userid = S_VI_MATCH ? S_VI_MATCH[1] : AMCV_MATCH ? AMCV_MATCH[1] : null
  return userid ? decodeURIComponent(userid) : userid
}

const getExtraFilters = appliedFilters => {
  if (!(appliedFilters && appliedFilters.length)) {
    return []
  }

  return appliedFilters
    .filter(filter => filter.id !== 'category')
    .map(filter => {
      return filter.values.map(value => {
        const filterId = filter.id === 'brands' ? 'brand' : filter.id
        return `${filterId}:${value}`
      })
    })
}

const encodeUrl = (term = '', placement = '', citrusCampaignName = '') => {
  if (!term || !placement || !citrusCampaignName) return null
  const encodedTerm = Buffer.from(term.toString()).toString('base64')
  const encodedPlacement = Buffer.from(placement).toString('base64')
  const encodedCampaignName = Buffer.from(citrusCampaignName.slice(0, -33)).toString('base64')
  const autoLink = `/sponsored/${encodedTerm}/${encodedPlacement}/${encodedCampaignName}`

  return autoLink
}

export {
  isProdListDesktopValid,
  isProdListMobileTabletValid,
  isProdListValid,
  insertProducts,
  getMaxNumberOfAds,
  hasMinNumberOfProductsToMergeForPlatform,
  getMaxNumberOfCitrusProductsToDisplayForPlatform,
  mergeProducts,
  citrusCategoryProductHandler,
  getCitrusProducts,
  getUserIdFromCookieInfo,
  getExtraFilters,
  encodeUrl
}
