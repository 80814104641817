/* eslint-disable no-nested-ternary */
import React from 'react'

import PropTypes from 'prop-types'
import { ErrorBoundary } from '@sainsburys-tech/bolt'
import ProductCardGroup from '../ProductGroupCard/ProductGroupCard'
import { CHUNK_SIZE, chunkArray } from '../../helpers/AvailabilityHelper'

const ProductList = props => {
  const { products, hideSponsoredContent } = props
  const groupedProducts = chunkArray(products, CHUNK_SIZE)

  return (
    <ErrorBoundary>
      <div data-test='product-list'>
        {groupedProducts.map((chunkProduct, groupIndex) => {
          return (
            <ProductCardGroup
              chunkProduct={chunkProduct}
              groupIndex={groupIndex}
              {...props}
              key={groupIndex}
              hideSponsoredContent={hideSponsoredContent}
            />
          )
        })}
      </div>
    </ErrorBoundary>
  )
}

ProductList.propTypes = {
  destinationPageType: PropTypes.string,
  products: PropTypes.array.isRequired,
  showLoader: PropTypes.bool,
  hideSponsoredContent: PropTypes.bool
}

ProductList.defaultProps = {
  destinationPageType: '',
  showLoader: false,
  hideSponsoredContent: false
}

export default ProductList
