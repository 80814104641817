import { useState, useEffect } from 'react'

import Logger from '../utils/Logger'
import getCitrusBannersX from '../services/CitrusServiceBannerx'

const logger = new Logger()

const useCitrusBanners = (searchTerm, categoryName, placement, isSponsoredPage) => {
  const [banners, setBanners] = useState([])

  useEffect(() => {
    if (banners.length === 0) {
      getCitrusBannersX(searchTerm, categoryName, placement, isSponsoredPage)
        .then(response => {
          const { ads } = response
          if (ads.length > 0) {
            const adsBanners = ads[0]?.banners
            setBanners(adsBanners)
          }
        })
        .catch(err => {
          logger.error('apiLogger', err, 'Unable to fetch Citrus Banners.')
        })
    }
  }, [searchTerm, categoryName])

  return banners
}

useCitrusBanners.defaultProps = {
  isSponsoredPage: false
}

export default useCitrusBanners
