import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { SpecCardSkeleton, ProductCardSkeleton, ErrorBoundary } from '@sainsburys-tech/bolt'
import { InView } from 'react-intersection-observer'
import * as Styled from '../ProductList/styles'
import { contentAreaTypes } from '../../config'
import Templates from '../ProductList/ProductList.template'
import ProductListCard from '../ProductList/ProductListCard'
import {
  formatProducts,
  renderAvailabilityMessages,
  CHUNK_SIZE,
  getLocalisedPostcode,
  getAvailabilityRadius,
  hasCollectionStores,
  getCollectionStoreErrorMessage
} from '../../helpers/AvailabilityHelper'
import filterAvailabilityBySku from '../../helpers/AvailabilityFilteringHelper'
import {
  getInformationLabels,
  formatTuProducts,
  showTuLabels,
  isBrowseClothing,
  isTu,
  hasEqualVariants
} from '../../helpers/Availability/AvailabilityTuHelper'
import { trackAvailabilityCollectionStoreError } from '../../helpers/TaggingSerializer.js'
import useAsync from '../../hooks/useAsync'
import { getValue } from '../../utils/ConfigProvider'
import { availabilityIsEnabled, availabilityRadiusToggleIsEnabled } from '../../utils/availabilityIsEnabled'
import { isCitrusEnabled } from '../../utils/isCitrusEnabled'
import { generateM053LinksContentFromProduct } from '../../utils/cmsComponents'
import { reportCitrusBanner } from '../../actions/executeBeacon'

const CMS = loadable.lib(() => import(/* webpackChunkName: "cms-components" */ '@sainsburys-tech/bolt-cms-components'))

const ProductCardGroup = props => {
  const {
    products,
    chunkProduct,
    groupIndex,
    citrus,
    currentPage,
    isCannedSearch,
    isClearance,
    localisationPostcode,
    showLocalisationModal,
    numberOfResults,
    pageSize,
    pageType,
    relavancyRecallOffset,
    searchTerm,
    sendCitrusClickBeacon,
    sendCitrusImpressionBeacon,
    showLoader,
    templateType,
    userState,
    location,
    incrementAvailabilityErrorCount,
    isError,
    appliedFilters,
    setCollectionStoresError,
    showEMWBISModal,
    hideSponsoredContent
  } = props

  const { status, run, complete, resetHookState } = useAsync()
  const [availabilityLabels, setAvailabilityLabels] = useState([])

  const webChannel = getValue('features.availability.webChannel')
  const isLazy = groupIndex !== 0

  // Radius
  const defaultRadius = getAvailabilityRadius() // Getting radius from local storage or a default value
  const [radius, setRadius] = useState(defaultRadius)

  const getProductCardLayout = (templateType, pageType) => {
    if (getValue('features.productList.forceClothingTemplate')) {
      return Templates.clothingLayout
    }

    if (pageType === Templates.pageTypeBrowse) {
      if (templateType === Templates.fashionTemplate) {
        return Templates.clothingLayout
      }
      if (templateType === Templates.whiteGoodTemplate) {
        return Templates.whiteGoodTemplate
      }
    }
    return Templates.defaultTemplate
  }

  const layout = getProductCardLayout(templateType, pageType)
  const whiteGoodCategory = layout === Templates.whiteGoodTemplate
  const productCardClass = 'xs-6--none sm-4'
  const className = whiteGoodCategory ? 'xs-block xs-12--none md-12' : productCardClass

  const setLabels = (availabilityLabels = []) => {
    setAvailabilityLabels(prevState => [...prevState, ...availabilityLabels])
  }

  const fetchProductAvailability = async (partNumbers, postcode) => {
    try {
      const url = `/stores/api/orchestrator/v0/locator/availability?maxDistance=${radius}&maxResults=10&skuQty=${partNumbers}&channel=${webChannel}&postcode=${postcode}&origin=${postcode}&storeSort=TA`
      const response = await fetch(url)

      const data = await response.json()

      if (!response.ok || !data) {
        throw new Error(`Availability for SKU cannot be fetched`)
      }

      // Find best availability for each partNumber
      const partNumberArray = partNumbers.replace(/_1/g, '').split(',')
      const filteredAvailabilityLabels = partNumberArray.map(partNumber => filterAvailabilityBySku(partNumber, data))

      setLabels(filteredAvailabilityLabels)

      const hasStores = hasCollectionStores(data)
      if (!hasStores && !isLazy) {
        const collectionStoreErrorMessage = getCollectionStoreErrorMessage(availabilityRadiusToggleIsEnabled)
        setCollectionStoresError(true) // Displays an error when there is no collection stores within the radius
        trackAvailabilityCollectionStoreError(pageType, collectionStoreErrorMessage)
      }

      return data
    } catch (err) {
      incrementAvailabilityErrorCount()
      Promise.reject(err.message)
    }
  }

  const availabilityEnabled = availabilityIsEnabled({
    pageType,
    urlPath: location?.pathname
  })

  const isClothingPage = isTu || isBrowseClothing(pageType, templateType)
  const hasTuAvailabilityLabels = isClothingPage ? showTuLabels(products, appliedFilters) : false

  const setAvailabilityInformationLabel = () => {
    const productsWithVariants = getInformationLabels(chunkProduct)
    if (productsWithVariants.length > 0) setLabels(productsWithVariants)
  }

  const getPartNumbers = () => {
    if (isClothingPage) {
      return hasTuAvailabilityLabels ? formatTuProducts(chunkProduct) : ''
    }

    return formatProducts(chunkProduct) // formats products for Argos & Habitat
  }

  const setInformationLabel = () => {
    if (isClothingPage) {
      return hasTuAvailabilityLabels && setAvailabilityInformationLabel()
    }

    !hasEqualVariants(products) && setAvailabilityInformationLabel() // prevents info labels being shown on Argos search if all products have variants
  }

  const fetchAvailability = () => {
    const partNumbers = getPartNumbers() // returns partNumbers in a string format
    const postcode = getLocalisedPostcode(localisationPostcode)

    if (availabilityEnabled && !isError) {
      setInformationLabel()
      if (postcode && partNumbers) {
        run(fetchProductAvailability(partNumbers, postcode))
      }
    }
  }

  // Check if radius has changed in local storage when modal is closed
  useEffect(() => {
    if (!showLocalisationModal && availabilityEnabled) {
      const defaultRadius = getAvailabilityRadius()
      if (defaultRadius !== radius) {
        setRadius(defaultRadius)
      }
    }
  }, [showLocalisationModal])

  // Reset hook state when products change. This is required when paginating
  useEffect(() => {
    if (chunkProduct && availabilityEnabled) resetHookState()
  }, [chunkProduct])

  // Fetch availability for 1st group of products when dependancies change
  useEffect(() => {
    if (availabilityEnabled) {
      availabilityLabels.length > 0 && setAvailabilityLabels([]) // reset state to prevent duplicate labels
      if (!isLazy && !showLoader) fetchAvailability()
    }
  }, [localisationPostcode, showLoader, radius])

  const onProductGroupView = inView => {
    if (inView && isLazy && !complete) {
      fetchAvailability()
    }
  }

  const Skeleton = () => (
    <Styled.SkeletonWrapper className={className}>
      <ProductCardSkeleton isPortrait={layout === Templates.clothingLayout} mobileLayout='vertical' />
    </Styled.SkeletonWrapper>
  )
  const SpecSkeleton = () => (
    <Styled.SkeletonWrapper whiteGoods className={className}>
      <SpecCardSkeleton />
    </Styled.SkeletonWrapper>
  )

  const ProductCard = loadable(
    () => import(/* webpackChunkName: "labs-product-card" */ '../ProductList/StyledProductCard.js'),
    {
      fallback: <Skeleton />
    }
  )

  return (
    <InView skip={!isLazy} rootMargin='250px 0px' onChange={onProductGroupView}>
      <Styled.ProductList data-test={`product-group-card-${groupIndex}`} key={groupIndex} isFirstGroup={!isLazy}>
        {chunkProduct.map((product, productIndex) => {
          const index = CHUNK_SIZE * groupIndex + productIndex
          const partNumber = product?.id ? product.id : `${product.type}-${productIndex}`
          const attributes = { ...product.attributes, partNumber }
          const productCardOnClick = () => {
            if (isCitrusEnabled({ pageType })) {
              sendCitrusClickBeacon(attributes, citrus, partNumber)
            }
          }

          if (product.type === contentAreaTypes.ContentInList) {
            if (hideSponsoredContent) return null
            const links = product?.links ? product.links : generateM053LinksContentFromProduct(product)

            return !showLoader ? (
              <ErrorBoundary key={`M053-${index}-boundary`}>
                <CMS>
                  {({ components }) => {
                    const { M053 } = components
                    const handleClick = () => {
                      if (product.isBannerGrid) {
                        return reportCitrusBanner(product.beaconId, 'click')
                      }
                      productCardOnClick()
                    }
                    const ProductCardM053 = (
                      <ProductCard
                        as='div'
                        isM053
                        data-test='product-list-m053'
                        key={`M053-${index}`}
                        onClick={handleClick}>
                        <M053
                          heading={product.heading}
                          image={product.image}
                          links={links}
                          callouts={product.callouts}
                          bodyCopy={product.bodyCopy}
                          legalCopy={product.legalCopy}
                        />
                      </ProductCard>
                    )
                    return (
                      <Styled.LazyHydrateCard whenVisible style={{ display: 'flex' }} className={className}>
                        {product.isBannerGrid ? (
                          <InView
                            style={{ display: 'flex', width: '100%' }}
                            triggerOnce
                            onChange={view =>
                              view && product.beaconId && reportCitrusBanner(product.beaconId, 'impression')
                            }>
                            {ProductCardM053}
                          </InView>
                        ) : (
                          ProductCardM053
                        )}
                      </Styled.LazyHydrateCard>
                    )
                  }}
                </CMS>
              </ErrorBoundary>
            ) : !whiteGoodCategory ? (
              <Skeleton />
            ) : (
              <SpecSkeleton />
            )
          }

          return (
            <ErrorBoundary key={`${partNumber}-${index}-boundary`}>
              <ProductListCard
                attributes={attributes}
                citrus={citrus}
                className={className}
                currentPage={currentPage}
                index={index}
                isCannedSearch={isCannedSearch}
                isClearance={isClearance}
                layout={layout}
                localisationPostcode={localisationPostcode}
                numberOfResults={numberOfResults}
                pageSize={pageSize}
                pageType={pageType}
                partNumber={partNumber}
                productCardOnClick={productCardOnClick}
                relavancyRecallOffset={relavancyRecallOffset}
                searchTerm={searchTerm}
                sendCitrusClickBeacon={sendCitrusClickBeacon}
                sendCitrusImpressionBeacon={sendCitrusImpressionBeacon}
                showLoader={showLoader}
                templateType={templateType}
                userState={userState}
                whiteGoodCategory={whiteGoodCategory}
                location={location}
                incrementAvailabilityErrorCount={incrementAvailabilityErrorCount}
                isError={isError}
                availabilityLabel={renderAvailabilityMessages(product, availabilityLabels)}
                availabilityStatus={status}
                showEMWBISModal={showEMWBISModal}
              />
            </ErrorBoundary>
          )
        })}
      </Styled.ProductList>
    </InView>
  )
}

ProductCardGroup.propTypes = {
  products: PropTypes.array.isRequired,
  chunkProduct: PropTypes.array.isRequired,
  groupIndex: PropTypes.number.isRequired,
  citrus: PropTypes.object.isRequired,
  currentPage: PropTypes.number,
  isCannedSearch: PropTypes.bool,
  isClearance: PropTypes.bool,
  localisationPostcode: PropTypes.string,
  showLocalisationModal: PropTypes.bool,
  numberOfResults: PropTypes.number,
  pageSize: PropTypes.number,
  pageType: PropTypes.string,
  relavancyRecallOffset: PropTypes.number,
  searchTerm: PropTypes.string,
  sendCitrusClickBeacon: PropTypes.func,
  sendCitrusImpressionBeacon: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  templateType: PropTypes.string,
  userState: PropTypes.object,
  location: PropTypes.object.isRequired,
  incrementAvailabilityErrorCount: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  appliedFilters: PropTypes.bool,
  setCollectionStoresError: PropTypes.func.isRequired,
  showEMWBISModal: PropTypes.func.isRequired,
  hideSponsoredContent: PropTypes.bool
}

ProductCardGroup.defaultProps = {
  currentPage: 1,
  isCannedSearch: false,
  isClearance: false,
  localisationPostcode: '',
  showLocalisationModal: false,
  numberOfResults: null,
  pageSize: 30,
  pageType: 'search',
  relavancyRecallOffset: 0,
  searchTerm: '',
  sendCitrusClickBeacon: () => {},
  showLoader: false,
  templateType: '',
  userState: {},
  appliedFilters: [],
  hideSponsoredContent: false
}

export default ProductCardGroup
