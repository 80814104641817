import { Themes } from '@sainsburys-tech/bolt'
import { ArgosVocab, HabitatVocab, TuVocab } from '@sainsburys-tech/bolt-vocab-provider'
import pkg from '../../package.json'

// This file is used by both the client and the server.
// Do not include API keys or server functions, as these will be included in the client bundle.

const boltUiVersion = pkg.dependencies['@sainsburys-tech/boltui-style'].replace('^', '')

export default {
  argos: {
    key: 'argos',
    salesChannel: 'argos',
    canonicalBase: 'https://www.argos.co.uk',
    faviconPath: '/i/Argos/ArgosFavicon',
    tealiumPath: 'argos/main/qa',
    theme: Themes.ArgosTheme,
    vocab: ArgosVocab,
    bolt: {
      namespace: 'bolt-v2',
      stylesheet: `//www.argos.co.uk/assets/bolt2/${boltUiVersion}/bolt.min.css`
    },
    features: {
      taxonomy: {
        vocabId: ''
      },
      addToTrolley: {
        modal: {
          enabled: true,
          hideExtras: false,
          hideCategories: false
        }
      },
      backInStock: {
        enabled: true
      },
      bestSellers: {
        enabled: true
      },
      sponsoredProducts: {
        enabled: true
      },
      wishlist: {
        enabled: true
      },
      productList: {
        forceClothingTemplate: false,
        forceEnableAltImageHover: false,
        disableComRel: false,
        additionalBrandLogos: null,
        enableSortbyNew: true,
        includeRelevancyNewness: false
      },
      productCard: {
        colorSwatchInline: true,
        colorSwatchAlwaysVisible: false,
        colorSwatchMaxVariations: 4,
        colorSwatchSize: 'small',
        showClearanceBadge: true,
        enableValueMessaging: true,
        disableClickPR: false
      },
      detailAttributesWhiteGoods: [
        'Size (cm)',
        'Frozen Food Capacity (Litre)',
        'Frost free',
        'Maximum spin speed (rpm)',
        'Number of programmes',
        'Wash and Dry Cycle Capacity (kg)',
        'Precise wash capacity (kg)',
        'Dryer type',
        'Drying capacity (kg)',
        'Place settings'
      ],
      listerTools: {
        hideMobileResultsCount: false,
        offsetSelector: null,
        safeZone: 375,
        reverseScrollBehaviour: false
      },
      seoFooter: {
        hideOnAppliedFilters: true
      },
      categoryList: {
        contentAreaNav: true
      },
      filterPanel: {
        transposeCategories: []
      },
      filterPills: {
        enabled: false,
        filters: {
          browse: [],
          search: [],
          default: []
        }
      },
      goInStoreWidget: {
        enabled: false
      },
      availability: {
        enabled: true,
        chunkSize: 6,
        hasLocalisationHeader: true,
        webChannel: 'web_plp',
        radius: {
          radiusDefaultValue: 10,
          radiusOptions: [
            { title: '1 mile', value: 1 },
            { title: '3 miles', value: 3 },
            { title: '5 miles', value: 5 },
            { title: '10 miles', value: 10 },
            { title: '15 miles', value: 15 },
            { title: '20 miles', value: 20 },
            { title: '25 miles', value: 25 }
          ],
          radiusLocalStorageKey: 'availability-radius'
        }
      },
      availabilityTooltip: {
        enabled: true
      },
      refinedTitlePage: {
        enabled: true,
        maxRefinements: 2,
        useRefinementTypeConfig: true,
        excludedParentCategoryIds: ['65875'] // Don't apply refinements for direct child categories for this ID (Toy Promotions)
      },
      citrus: {
        banners: {
          enabled: true
        },
        gridBanners: {
          enabled: true
        }
      },
      similarAi: {
        enabled: true,
        routes: ['/sd/', '/browse/']
      }
    }
  },
  habitat: {
    key: 'habitat',
    salesChannel: 'habitat',
    canonicalBase: 'https://www.habitat.co.uk',
    faviconPath: '/i/Argos/HabitatFavicon',
    tealiumPath: 'argos/habitat/qa',
    theme: Themes.HabitatTheme,
    vocab: HabitatVocab,
    bolt: {
      namespace: 'bolt-habitat',
      stylesheet: `//www.argos.co.uk/assets/bolt2/${boltUiVersion}/bolt-habitat.min.css`
    },
    features: {
      taxonomy: {
        vocabId: 'PROD Habitat Navigation'
      },
      addToTrolley: {
        modal: {
          enabled: true,
          hideExtras: true,
          hideCategories: true
        }
      },
      backInStock: {
        enabled: false
      },
      bestSellers: {
        enabled: false
      },
      sponsoredProducts: {
        enabled: false
      },
      wishlist: {
        enabled: false
      },
      productList: {
        forceClothingTemplate: false,
        forceEnableAltImageHover: false,
        disableComRel: false,
        additionalBrandLogos: null,
        enableSortbyNew: true,
        includeRelevancyNewness: false
      },
      productCard: {
        colorSwatchInline: true,
        colorSwatchAlwaysVisible: false,
        colorSwatchMaxVariations: 4,
        colorSwatchSize: 'small',
        showClearanceBadge: true,
        enableValueMessaging: true,
        disableClickPR: false
      },
      listerTools: {
        hideMobileResultsCount: false,
        offsetSelector: null,
        safeZone: 375,
        reverseScrollBehaviour: false
      },
      seoFooter: {
        hideOnAppliedFilters: false
      },
      categoryList: {
        contentAreaNav: true
      },
      filterPanel: {
        transposeCategories: []
      },
      filterPills: {
        enabled: false,
        filters: {
          browse: [],
          search: [],
          default: []
        }
      },
      goInStoreWidget: {
        enabled: true
      },
      availability: {
        enabled: true,
        chunkSize: 6,
        hasLocalisationHeader: true,
        webChannel: 'web_plp',
        radius: {
          radiusDefaultValue: 10,
          radiusOptions: [
            { title: '1 mile', value: 1 },
            { title: '3 miles', value: 3 },
            { title: '5 miles', value: 5 },
            { title: '10 miles', value: 10 },
            { title: '15 miles', value: 15 },
            { title: '20 miles', value: 20 },
            { title: '25 miles', value: 25 }
          ],
          radiusLocalStorageKey: 'availability-radius'
        }
      },
      availabilityTooltip: {
        enabled: true
      },
      refinedTitlePage: {
        enabled: false,
        maxRefinements: 3,
        refinementTypes: [],
        departments: []
      },
      citrus: {
        banners: {
          enabled: false
        },
        gridBanners: {
          enabled: false
        }
      },
      similarAi: {
        enabled: false,
        routes: ['/sd/', '/browse/']
      }
    }
  },
  tu: {
    key: 'tu',
    salesChannel: 'tu',
    canonicalBase: 'https://tuclothing.sainsburys.co.uk',
    faviconPath: '/i/Argos/TuFavicon',
    tealiumPath: 'argos/tuc/qa',
    theme: Themes.TuTheme,
    vocab: TuVocab,
    bolt: {
      namespace: 'bolt-tu',
      stylesheet: `//www.argos.co.uk/assets/bolt2/${boltUiVersion}/bolt-tu.min.css`
    },
    features: {
      taxonomy: {
        vocabId: 'PROD Tu Navigation'
      },
      addToTrolley: {
        modal: {
          enabled: false,
          hideExtras: false,
          hideCategories: false
        }
      },
      backInStock: {
        enabled: false
      },
      bestSellers: {
        enabled: false
      },
      sponsoredProducts: {
        enabled: false
      },
      wishlist: {
        enabled: false
      },
      productList: {
        forceClothingTemplate: true,
        forceEnableAltImageHover: true,
        disableComRel: true,
        disableTuLogo: false,
        additionalBrandLogos: [
          { name: 'Joules', logoUrl: 'https://media.4rgos.it/i/Argos/joules' },
          {
            name: 'NOISY MAY',
            logoUrl: 'https://media.4rgos.it/i/Argos/NOISY-MAY-LOGO'
          },
          { name: 'VERO MODA CURVE', logoUrl: 'https://media.4rgos.it/i/Argos/Vero-Moda-Curve_100-black' },
          { name: 'Sloggi', logoUrl: 'https://media.4rgos.it/i/Argos/Sloggi_RGB' },
          { name: 'KIDS ONLY', logoUrl: 'https://media.4rgos.it/i/Argos/ONLY KIDS_black' },
          { name: 'ONLY PLAY', logoUrl: 'https://media.4rgos.it/i/Argos/ONLY PLAY_black' },
          { name: 'FIRE AND GLORY', logoUrl: 'https://media.4rgos.it/i/Argos/FG_black_logo_final_1' },
          { name: 'OASIS', logoUrl: 'https://media.4rgos.it/i/Argos/OASIS' },
          { name: 'PIECES', logoUrl: 'https://media.4rgos.it/i/Argos/pieces logo-2020_black' },
          {
            name: 'Caterpillar',
            logoUrl:
              'https://media.4rgos.it/i/Argos/purepng.com-caterpillar-logologobrand-logoiconslogos-2515199391586668y_1'
          },
          { name: 'MAMALICIOUS', logoUrl: 'https://media.4rgos.it/i/Argos/Mamalicious_black_121219' },
          { name: 'VILA', logoUrl: 'https://media.4rgos.it/i/Argos/VILA-LOGO-BLACK' },
          { name: 'Cotswold', logoUrl: 'https://media.4rgos.it/i/Argos/Cotswold-Logo_1' },
          { name: 'FatFace', logoUrl: 'https://media.4rgos.it/i/Argos/FF-Logotype_Grey_RGB_1' },
          { name: 'JUNAROSE', logoUrl: 'https://media.4rgos.it/i/Argos/junarose_black' },
          { name: 'Toezone', logoUrl: 'https://media.4rgos.it/i/Argos/Toezone_1' },
          { name: 'ONLY', logoUrl: 'https://media.4rgos.it/i/Argos/new-only-logo' },
          {
            name: 'Jack and Jones Junior',
            logoUrl: 'https://media.4rgos.it/i/Argos/jackjones_junior_logo_1_line_blue_rgb1'
          },
          { name: 'VERO MODA', logoUrl: 'https://media.4rgos.it/i/Argos/VERO MODA_black' },
          { name: 'Banana Bite', logoUrl: 'https://media.4rgos.it/i/Argos/BANANA BITE Logo' },
          { name: 'Brakeburn', logoUrl: 'https://media.4rgos.it/i/Argos/Brakeburn_BlueOnWhite' },
          { name: 'Finery', logoUrl: 'https://media.4rgos.it/i/Argos/Finery logo' },
          { name: 'Love our planet', logoUrl: 'https://media.4rgos.it/i/Argos/Love Our Planet Logo new' },
          { name: 'Lilly and Sid', logoUrl: 'https://media.4rgos.it/i/Argos/Lilly&Sid' },
          { name: 'Little Mistress', logoUrl: 'https://media.4rgos.it/i/Argos/LM-LOGO-2022-FINAL' },
          { name: 'Love Luna', logoUrl: 'https://media.4rgos.it/i/Argos/Love Luna Logo' },
          { name: 'Magic Feet', logoUrl: 'https://media.4rgos.it/i/Argos/Magic Feet Logo' },
          { name: 'Sosandar', logoUrl: 'https://media.4rgos.it/i/Argos/Sosandar_logo' },
          { name: 'Totes', logoUrl: 'https://media.4rgos.it/i/Argos/totes' },
          { name: 'Turtledove London', logoUrl: 'https://media.4rgos.it/i/Argos/turtledove logo' },
          { name: 'Wonderbra', logoUrl: 'https://media.4rgos.it/i/Argos/Wonderbra Logo' },
          { name: 'Triumph', logoUrl: 'https://media.4rgos.it/i/Argos/large JPG-New Triumph logo RGB-1' },
          { name: 'Union Works', logoUrl: 'https://media.4rgos.it/i/Argos/UNION WORKS LOGO' },
          { name: 'Everbelle', logoUrl: 'https://media.4rgos.it/i/Argos/Everbelle Logo' },
          { name: 'Reaktiv', logoUrl: 'https://media.4rgos.it/i/Argos/REAKTIV Logo' },
          { name: 'For All The Love', logoUrl: 'https://media.4rgos.it/i/Argos/FOR ALL THE LOVE LOGO' },
          { name: 'Fred and Noah', logoUrl: 'https://media.4rgos.it/i/Argos/Fred & Noah Logo' },
          { name: 'Umbro', logoUrl: 'https://media.4rgos.it/i/Argos/Umbro-logo-and-wordmark_black' },
          { name: 'Anorak', logoUrl: 'https://media.4rgos.it/i/Argos/anorak_logo_orange_text' },
          { name: 'Skechers', logoUrl: 'https://media.4rgos.it/i/Argos/CORP_SKX_BLU-logo_1' },
          { name: 'Hush Puppies', logoUrl: 'https://media.4rgos.it/i/Argos/HP Logo Horiz Large 2019' },
          { name: 'Rocket Dog', logoUrl: 'https://media.4rgos.it/i/Argos/Rocket Dog_logo' },
          { name: 'Superga', logoUrl: 'https://media.4rgos.it/i/Argos/Institutional_Superga_logo_horizontal_noBG' },
          { name: 'Burgs', logoUrl: 'https://media.4rgos.it/i/Argos/BURGS HIGH RESOLUTION LOGO transparent' },
          { name: 'Grass and Air', logoUrl: 'https://media.4rgos.it/i/Argos/Grass-and-Air' },
          { name: 'Vogue Williams', logoUrl: 'https://media.4rgos.it/i/Argos/LM-BY-VOGUE-WILLIAMS' },
          { name: 'Simply Be', logoUrl: 'https://media.4rgos.it/i/Argos/sb-logo-transparent' },
          { name: 'Thought', logoUrl: 'https://media.4rgos.it/i/Argos/Thought_Clothing_Logo_black' },
          { name: 'Speedo', logoUrl: 'https://media.4rgos.it/i/Argos/SpeedoLogo_Stack_Red_CMYK' },
          { name: 'Oola Lingerie', logoUrl: 'https://media.4rgos.it/i/Argos/HROOLA' },
          { name: 'Four Seasons', logoUrl: 'https://media.4rgos.it/i/Argos/FS LOGO' },
          { name: 'NYDJ', logoUrl: 'https://media.4rgos.it/i/Argos/Logo_nydj_landscape_black' },
          { name: 'Original Penguin', logoUrl: 'https://media.4rgos.it/i/Argos/OPG-DOUBLE-LOGO-BLACK' },
          { name: 'Osprey London', logoUrl: 'https://media.4rgos.it/i/Argos/OSPREY-LOGO' },
          { name: 'Jacamo', logoUrl: 'https://media.4rgos.it/i/Argos/JACAMO-LOGO-BLACK' },
          { name: 'Farah', logoUrl: 'https://media.4rgos.it/i/Argos/FARAH-LOGO-BLACK' },
          { name: 'Casual Friday', logoUrl: 'https://media.4rgos.it/i/Argos/CASUAL-FRIDAY-LOGO' },
          { name: 'Hey Dude', logoUrl: 'https://media.4rgos.it/i/Argos/HEY-DUDE-LOGO' },
          { name: 'Figleaves', logoUrl: 'https://media.4rgos.it/i/Argos/FIGLEAVES-LOGO' },
          { name: 'French Connection', logoUrl: 'https://media.4rgos.it/i/Argos/FRENCH-CONNECTION-LOGO' },
          { name: 'Ted Baker', logoUrl: 'https://media.4rgos.it/i/Argos/TED-BAKER-LOGO' },
          { name: 'Moda In Pelle', logoUrl: 'https://media.4rgos.it/i/Argos/MODA-LOGO-BLACK' },
          { name: 'Hummel', logoUrl: 'https://media.4rgos.it/i/Argos/HUMMEL-LOGO-LONG-BLACK' },
          { name: 'Ilse Jacobsen', logoUrl: 'https://media.4rgos.it/i/Argos/ILSE-JACOBSEN-LOGO-BLACK' },
          { name: 'Elie Beaumont', logoUrl: 'https://media.4rgos.it/i/Argos/ELIE-BEAUMONT-LOGO-BLACK' },
          { name: 'Joe Browns', logoUrl: 'https://media.4rgos.it/i/Argos/JOE-BROWNS-LOGO' },
          { name: 'Ben Sherman', logoUrl: 'https://media.4rgos.it/i/Argos/BEN-SHERMAN-LOGO-BLACK' },
          { name: 'Kaffe', logoUrl: 'https://media.4rgos.it/i/Argos/KAFFE-LOGO-BLACK' },
          { name: 'Too Young To Be Bored', logoUrl: 'https://media.4rgos.it/i/Argos/TYTBB-LOGO-GREY' },
          { name: 'Havaianas', logoUrl: 'https://media.4rgos.it/i/Argos/HAVAIANAS-LOGO-RED' },
          { name: 'Scion', logoUrl: 'https://media.4rgos.it/i/Argos/TU-X-SCION-LOGO' },
          { name: 'Hawes and Curtis', logoUrl: 'https://media.4rgos.it/i/Argos/HAWES-AND-CURTIS-LOGO-BLACK' },
          { name: 'Start-Rite', logoUrl: 'https://media.4rgos.it/i/Argos/START-RITE-LOGO-BLACK' },
          { name: 'JoJo Maman Bebe', logoUrl: 'https://media.4rgos.it/i/Argos/JOJO-MAMAN-BEBE-LOGO-BLUE' },
          { name: 'Blend', logoUrl: 'https://media.4rgos.it/i/Argos/BLEND-LOGO-BLACK' },
          { name: 'JanSport', logoUrl: 'https://media.4rgos.it/i/Argos/JANSPORT-LOGO-BLACK' }
        ],
        enableSortbyNew: true,
        sortByRelevance: ['842051', '842050', '842028', '842038', '842041', '842035', '842025', '841891'],
        includeRelevancyNewness: true
      },
      productCard: {
        colorSwatchInline: false,
        colorSwatchAlwaysVisible: true,
        colorSwatchMaxVariations: 10,
        colorSwatchSize: 'small',
        turnOffCta: true,
        showClearanceBadge: false,
        enableValueMessaging: true,
        disableClickPR: true
      },
      listerTools: {
        hideMobileResultsCount: false,
        offsetSelector: '[data-module-name="Header"] header',
        safeZone: 205,
        reverseScrollBehaviour: true
      },
      seoFooter: {
        hideOnAppliedFilters: false
      },
      categoryList: {
        contentAreaNav: false
      },
      filterPanel: {
        transposeCategories: ['798600']
      },
      filterPills: {
        enabled: true,
        filters: {
          browse: ['size'],
          search: ['department', 'size'],
          default: []
        }
      },
      goInStoreWidget: {
        enabled: false
      },
      availability: {
        enabled: true,
        chunkSize: 6,
        hasLocalisationHeader: false,
        webChannel: 'web_plp_tu',
        radius: {
          radiusDefaultValue: 10,
          radiusOptions: [],
          radiusLocalStorageKey: null
        }
      },
      availabilityTooltip: {
        enabled: false
      },
      refinedTitlePage: {
        enabled: true,
        maxRefinements: 3,
        refinementTypes: ['sleeve length', 'price', 'type', 'style', 'brands', 'size'],
        departments: ['Women', 'Men', 'Boys', 'Girls', 'Baby']
      },
      citrus: {
        banners: {
          enabled: false
        },
        gridBanners: {
          enabled: false
        }
      },
      similarAi: {
        enabled: true,
        routes: ['/sd/', '/browse/']
      }
    }
  }
}
