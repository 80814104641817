import { getBrowsePath } from '@sainsburys-tech/boltui-utils'

import { BrowseHelper, MASTER_CATEGORY_ID } from './BrowseHelper'
import { CategoryHelper } from './CategoryHelper'
import findabilityDecoder from './FindabilityDecoder'
import { getValue } from '../utils/ConfigProvider'

const canonicalBaseDomain = () => getValue('canonicalBase')

export default class CanonicalHelper {
  constructor(categoryStoreState, productStoreState, applicationStoreState = null) {
    this.categoryStoreState = categoryStoreState || {}
    this.productStoreState = productStoreState || {}
    this.applicationStoreState = applicationStoreState || {}
  }

  static buildSearchCanonicalUrl(searchTerm = '', isCanned, canned, isSD, existsInSEO) {
    if (!searchTerm) return ''
    let page = 'search'
    if (isCanned && canned) {
      page = 'list'
    }
    if ((!isCanned && existsInSEO) || isSD) {
      page = 'sd'
    }
    return `${canonicalBaseDomain()}/${page}/${findabilityDecoder.encode(
      encodeURIComponent(searchTerm.toLowerCase())
    )}/`
  }

  get searchTerm() {
    return this.productStoreState.searchTerm
  }

  get numberOfResults() {
    return this.productStoreState.numberOfResults
  }

  get searchCanonical() {
    return this.productStoreState.searchCanonical
  }

  get currentPage() {
    return this.productStoreState.currentPage
  }

  get matchedFiltersUriFragments() {
    const { appliedFilterUriFragments } = this.productStoreState

    if (appliedFilterUriFragments && appliedFilterUriFragments.allFilters) {
      return appliedFilterUriFragments.allFilters
    }

    return {}
  }

  get categoryCanonicalTree() {
    return this.categoryStoreState.categoryCanonicalTree
  }

  get isSlaveCategory() {
    return this.categoryCanonicalTree && this.categoryCanonicalTree.length > 0
  }

  get categoryName() {
    return this.categoryStoreState.categoryName
  }

  get categoryId() {
    return this.categoryStoreState.categoryId
  }

  get categoryAncestors() {
    return this.categoryStoreState.ancestors
  }

  get isCategoryClearance() {
    return this.applicationStoreState.isClearance
  }

  get isSaleRoute() {
    return this.applicationStoreState.isSaleRoute
  }

  get seoMetaCanonicalTag() {
    return this.applicationStoreState?.seoMeta?.canonicalTag
  }

  get canonical() {
    if (this.searchTerm) {
      return this.numberOfResults > 0 ? this.searchCanonical : ''
    }

    // no search term or category id supplied
    if (!this.searchTerm && !this.categoryId) {
      return ''
    }

    return this.getBrowseCanonical()
  }

  getBrowseCanonical() {
    let params

    // Get canonical tag from Magnolia to override existing canonical logic (only if it exists)
    const canonicalTag = this.seoMetaCanonicalTag

    if (canonicalTag) {
      return canonicalTag
    }

    // master category
    if (String(this.categoryId) === String(MASTER_CATEGORY_ID)) {
      return `${canonicalBaseDomain()}/static/Home.htm`
    }

    if (this.isSlaveCategory) {
      params = {
        ancestors: this.categoryCanonicalTree,
        currentCatId: CategoryHelper.getCurrentCategoryId(this.categoryCanonicalTree),
        filterUriFragments: this.matchedFiltersUriFragments,
        pageNumber: this.currentPage,
        isClearance: this.isCategoryClearance
      }
    } else {
      params = {
        currentCatName: this.categoryName,
        ancestors: this.categoryAncestors,
        currentCatId: this.categoryId,
        filterUriFragments: this.matchedFiltersUriFragments,
        pageNumber: this.currentPage,
        isClearance: this.isCategoryClearance,
        isSale: this.isSaleRoute
      }
    }

    const canonicalPath = getBrowsePath(BrowseHelper.buildCorrectUrlBrowseParams(params))

    return `${canonicalBaseDomain()}${canonicalPath}`
  }
}
