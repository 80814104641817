import Logger from '../utils/Logger'
import { pick } from '../utils/PolyfillLodash'
import config, { searchTypes } from '../config'
import { encodeUrl } from './CitrusHelper'

const logger = new Logger()

const PRODUCT_ATTRIBUTES = [
  'name',
  'price',
  'wasPrice',
  'avgRating',
  'reviewsCount',
  'fastTrack',
  'specialOfferText',
  'hasVariations',
  'hasMultiplePrices',
  'freeDelivery',
  'badge',
  'variants',
  'deliverable',
  'reservable',
  'brand',
  'detailAttributes',
  'wasText',
  'relevancyRank',
  'definingAttributes',
  'fulfilmentAttributes',
  'productId' // products parentId
]

function getSearchTermForSearchType({ searchType, inputSearchTerm, searchTerm }) {
  if (searchType === searchTypes.brandsNIC || searchType === searchTypes.NRS) {
    return inputSearchTerm
  }

  return searchTerm
}

function getSearchType({ searchType, brandSuggestions }) {
  if (!searchType) return null
  if (searchType === searchTypes.brandsNIC && brandSuggestions && brandSuggestions.length < 1) {
    return null
  }
  if (searchType === searchTypes.NRS && brandSuggestions && brandSuggestions.length < 1) {
    return null
  }

  return searchType
}

function getProductCount(response, ...args) {
  logger.debug('functionLogger', { ...args }, 'ProductHelper getProductCount')
  let productCount = null

  if (response && response.meta) {
    productCount = response.meta.totalData >= 0 ? response.meta.totalData : null
  }
  return productCount
}

function renameProductIdToPartNumber(variants) {
  if (!variants) return

  Object.keys(variants).forEach(variantType => {
    variants[variantType].forEach(variant => {
      if (!variant.partNumber) {
        variant.partNumber = variant.productId
        delete variant.productId
      }
    })
  })
}

function formatResponse(response) {
  if (response?.data) {
    response?.data.forEach(product => {
      product.attributes = pick(product.attributes, PRODUCT_ATTRIBUTES)
      renameProductIdToPartNumber(product.attributes.variants)
    })
  }

  return response
}

function getSuggestedTerm(suggestedBrands) {
  if (suggestedBrands && suggestedBrands.length) return suggestedBrands[0].text.toLowerCase()
  return null
}

function splitByRelevancy(products) {
  let productLists

  if (!Array.isArray(products)) {
    return [[], []]
  }

  const productCount = Array.isArray(products) && products.length > 30 ? products.length : 30
  const index = products.findIndex(product => product?.attributes?.relevancyRank === 2)
  if (index >= 0) {
    const firstRelevancy = products.slice(0, index)
    const secondRelevancy = products.slice(index, productCount)
    productLists = [firstRelevancy, secondRelevancy]
  } else {
    productLists = [products, []]
  }
  return productLists
}

function mergeContentInList(products, inspirationCards = []) {
  const firstIndex = 3
  const mergedProducts = [...products.slice(0, firstIndex), inspirationCards[0], ...products.slice(firstIndex)]
  const secondIndex = mergedProducts.length > 20 ? mergedProducts.length - 3 : mergedProducts.length
  return inspirationCards.length > 1
    ? [...mergedProducts.slice(0, secondIndex), inspirationCards[1], ...mergedProducts.slice(secondIndex)]
    : mergedProducts
}

function isSkuId(partNumber) {
  let skuId = partNumber
    ?.toString()
    .trim()
    .match(/^[\d]{3}\/?[\d]{3,4}$/g)

  if (!skuId) {
    return false
  }

  skuId = skuId[0].split('/').join('')
  return skuId.length === 7 || skuId.length === 6
}

function isStrictTucPrefixedTuSkuId(partNumber) {
  return /^(tuc)[\d]{8,18}$/g.test(partNumber.toString().trim())
}

function getProductPagePath(partNumber) {
  return `${config.getProductPath(partNumber)}`
}

function findAttsByName(arr = [], searchNames) {
  const filteredArr = arr.filter(item => searchNames.includes(item?.name))
  return filteredArr.map(item => ({ name: item?.name, value: item.value }))
}

function findAndMoveFirst(arr, name) {
  const index = arr.findIndex(item => item?.name === name)
  if (index !== -1 && index !== 0) {
    const obj = arr[index]
    arr.splice(index, 1)
    arr.unshift(obj)
  }
  return arr
}

function mergeBannerGrid(contentInList, bannerGrid) {
  const autoLink = encodeUrl(bannerGrid.categoryName, 'argos-category', bannerGrid.banners[0].citrusCampaignName)
  contentInList.attributes = contentInList.attributes.map(attribute => {
    if (attribute?.urgent === false) {
      return {
        categoryName: bannerGrid.categoryName,
        beaconId: bannerGrid.banners[0].citrusAdId,
        isBannerGrid: true,
        type: 'M053',
        desc: bannerGrid.banners[0].headingText,
        heading: bannerGrid.banners[0].headingText,
        image: {
          altText: bannerGrid.banners[0].heroImageAltText,
          source: bannerGrid.banners[0].heroImage,
          url: bannerGrid.banners[0].heroImage
        },
        links: [
          {
            title: bannerGrid.banners[0].headingText,
            url: bannerGrid.banners[0].ctaLink || autoLink,
            target: '_self'
          }
        ],
        callouts: attribute.callouts
      }
    }
    return attribute
  })
  return contentInList.attributes
}

const removeDuplicateColors = array => {
  const seenLabels = new Set()
  return array.reduce((acc, item) => {
    if (!seenLabels.has(item.label)) {
      seenLabels.add(item.label)
      acc.push(item)
    }
    return acc
  }, [])
}

const getUniqueColorSwatches = array => {
  const uniqueSwatchColors = []
  const duplicateSwatchColors = []
  const seenSwatches = new Set()
  const seenColors = new Set()

  array.forEach(swatch => {
    const color = swatch?.label
    const fabric = swatch?.fabric

    const swatchId = `${color}-${fabric}`

    // Add unique swatches only
    if (!seenSwatches.has(swatchId)) {
      seenSwatches.add(swatchId)
      // if color is already seen, then set as duplicate
      if (seenColors.has(color)) {
        duplicateSwatchColors.push(swatch)
      } else {
        // if color is not seen, then set as unique
        seenColors.add(color)
        uniqueSwatchColors.push(swatch)
      }
    }
  })

  // return swatches with unique colors first, then swatches with duplicate colors
  return uniqueSwatchColors.concat(duplicateSwatchColors)
}

const getColorVariants = colorDefiningAttributes => {
  return removeDuplicateColors(colorDefiningAttributes).map(attribute => ({
    partNumber: attribute.id || '',
    variantKey: attribute.key || '',
    variantLabel: attribute.label || ''
  }))
}

// Swatch = Color + Fabric pairing
const getFabricSwatchVariants = (colorDefiningAttributes, fabricDefiningAttributes) => {
  const swatchVariants = colorDefiningAttributes?.map(variant => {
    const fabricType = fabricDefiningAttributes?.find(fabric => fabric.id === variant.id)
    return { ...variant, fabric: fabricType?.label }
  })

  return getUniqueColorSwatches(swatchVariants).map(attribute => ({
    partNumber: attribute.id || '',
    variantKey: attribute.key || '',
    variantLabel: attribute.label || '',
    variantFabric: attribute.fabric || ''
  }))
}

export {
  getSuggestedTerm,
  formatResponse,
  renameProductIdToPartNumber,
  getProductCount,
  getSearchType,
  getSearchTermForSearchType,
  splitByRelevancy,
  mergeContentInList,
  isSkuId,
  isStrictTucPrefixedTuSkuId,
  getProductPagePath,
  findAttsByName,
  findAndMoveFirst,
  mergeBannerGrid,
  getColorVariants,
  getFabricSwatchVariants
}
