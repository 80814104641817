import { get } from '../utils/PolyfillLodash'
import { getCitrusUrl, generateCitrusRequestParams } from '../helpers/CitrusRequestHelper'
import { isProdListValid, getUserIdFromCookieInfo } from '../helpers/CitrusHelper'
import mergePdpDataToCitrus from '../helpers/MergePdpDataToCitrus'
import citrusService from '../services/CitrusService'
import beaconService from '../services/BeaconService'
import { clearCitrusProducts, handleCitrus, showCitrusProducts } from '../redux/citrus/citrus'

import Logger from '../utils/Logger'

const logger = new Logger()

const hasUnsupportedFilters = appliedFilters => {
  if (!(appliedFilters && appliedFilters.length)) {
    return
  }

  const unsupportedFilters = [] // Add filters not supported by Citrus here

  return appliedFilters.filter(filter => unsupportedFilters.includes(filter.id)).length > 0
}

const getPartNumbers = citrusResponse => {
  return get(citrusResponse, 'body.ads', [])
    .map(v => {
      return v.gtin
    })
    .join(',')
}

const isCitrusResponseValid = res => {
  return get(res, 'body.ads') && Array.isArray(res.body.ads) && res.body.ads.length > 0
}

const isPdpResponseValid = res =>
  res.data && ((res.data.length > 0 && res.data[0]?.type === 'products') || res.data?.type === 'products')

const normalizeCitrusResponseData = data => {
  const myProductData = get(data, 'ads')
  return {
    body: {
      PageBeacon: get(data, 'PageBeacon', ''),
      ads: myProductData
    }
  }
}

const fireBeacon = response => {
  if (response && response.body && response.body.PageBeacon) {
    beaconService(response.body.PageBeacon)
  }
}

const enrichProductData = (context, response) => {
  const partNumbers = getPartNumbers(response)
  context.service.read('pdp', { partNumbers }, {}, (error, pdpResponse) => {
    if (error || !isPdpResponseValid(pdpResponse)) {
      return context.reduxStore.dispatch(clearCitrusProducts())
    }
    const hlData = mergePdpDataToCitrus({ response, pdpResponse })
    const ads = get(hlData, 'ads', [])
    if (!isProdListValid(ads)) {
      return context.reduxStore.dispatch(clearCitrusProducts())
    }
    return context.reduxStore.dispatch(handleCitrus(hlData))
  })
}

const showSkeletonLoaders = (context, response) => {
  const body = get(response, 'body', {})
  const products = body.ads || body.ProductAd
  if (products) {
    products.forEach(product => {
      product.Price = null
    })
    context.reduxStore.dispatch(handleCitrus({ ads: products }))
  }
}

export default function executeCitrus(actionContext, payload) {
  const { appliedFilters } = payload

  const userid = getUserIdFromCookieInfo(document.cookie)
  const catalogId = window.citrusCatalogId
  const requestBody = generateCitrusRequestParams({
    sessionId: userid,
    catalogId,
    ...payload
  })
  const citrusUrl = getCitrusUrl()

  return citrusService(citrusUrl, requestBody)
    .then(response => {
      const citrusResponse = normalizeCitrusResponseData(response)
      // @TODO: The impression should fire when the sponsored products are within the browser viewport and not before
      fireBeacon(citrusResponse)

      // clear citrus for certain filter combinations
      if (hasUnsupportedFilters(appliedFilters)) {
        return actionContext.reduxStore.dispatch(clearCitrusProducts())
      }

      // continue if citrus response valid and if it has 3 results for desktop, 2 mobile
      if (isCitrusResponseValid(citrusResponse) && isProdListValid(get(citrusResponse, 'body.ads', []))) {
        actionContext.reduxStore.dispatch(showCitrusProducts())
        showSkeletonLoaders(actionContext, citrusResponse)
        return enrichProductData(actionContext, citrusResponse)
      }

      return actionContext.reduxStore.dispatch(clearCitrusProducts())
    })
    .catch(err => {
      logger.error('apiLogger', err, 'Unable to fetch Citrus.')
      return actionContext.reduxStore.dispatch(clearCitrusProducts())
    })
}
